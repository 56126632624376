import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import {
  Button,
  Card,
  CardBody,
  CardTitle,
  Col,
  Container,
  Form,
  Input,
  Label,
  Row,
  FormFeedback
} from "reactstrap";
import { useSelector, useDispatch } from "react-redux";
import { isEmpty } from "lodash";
import Breadcrumbs from "../../../components/Common/Breadcrumb";
import "react-rangeslider/lib/index.css"
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { useFormik } from "formik";
import * as Yup from "yup";
import {
  updateContact as onUpdateContact,
  getContactMega as onGetContact,
} from "store/contacts/actions";

import toastr from "toastr";
import "toastr/build/toastr.min.css";

const EcommerceAddProduct = () => {
  document.title = "İletişim Bilgileri | Yakın Mutfak Admin Paneli";
  const dispatch = useDispatch();
  const [getContactEdit, setContactEdit] = useState(null);
  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      email: (getContactEdit && getContactEdit.Mail) || '',
      gsmNumber: (getContactEdit && getContactEdit.Phone) || '',
      adress: (getContactEdit && getContactEdit.Adress) || '',
   
      facebook:(getContactEdit && getContactEdit.FacebookAddress) || '',
      instagram:(getContactEdit && getContactEdit.InstagramAddress) || '',
      twitter:(getContactEdit && getContactEdit.TwitterAddress) || '',

      lat:(getContactEdit && getContactEdit.Lat) || '',
      long:(getContactEdit && getContactEdit.Long) || '',

    },
    validationSchema: Yup.object({
      adress: Yup.string().required("Lütfen Başlık Giriniz")
    }),
    onSubmit: (values) => {

      const updateOrder = {
        ID: users ? users[0].ID : 0,
        Mail: values.email,
        Phone: values.gsmNumber,
        Adress: values.adress,
      
        FacebookAddress:values.facebook,
        InstagramAddress:values.instagram,
        TwitterAddress:values.twitter,
        Long:values.long,
        Lat:values.lat

      };
      // update order
      dispatch(onUpdateContact(updateOrder));

      dispatch(onGetContact());
      showToast(true)

      //validation.resetForm();

    },
  });


  function showToast(type) {
    let ele = null
    let message = "";
  
     if(type == true){
       ele = "success"
       message = "Başarıyla Bilgileriniz Güncellendi"
    } else{
       ele = "error"
       message = getFalseDesc
    };
    const position = ["toast-top-right"];
    let toastType;
    const title = "Başlık";
  
  
    //Close Button
    const closeButton = true;
  
    //Debug
    const debug = false;
  
    //Progressbar
    const progressBar = true;
  
    //Duplicates
    const preventDuplicates = false;
  
    //Newest on Top
    const newestOnTop = true;
  
    //position class
    let positionClass = "toast-top-right";
  
    //Show Easing
    const showEasing = "swing";
  
    //Hide Easing
    const hideEasing = "linear";
  
    //show method
    const showMethod = "fadeIn";
  
    //Hide method
    const hideMethod = "fadeOut";
  
    //show duration
    const showDuration = 300;
  
    //Hide duration
    const hideDuration = 1000;
  
    //timeout
    const timeOut = 5000;
  
    //extended timeout
    const extendedTimeOut = 1000;
  
    //Fetch checked Type
   
     toastType = ele;
    
  
    toastr.options = {
      positionClass: positionClass,
      timeOut: timeOut,
      extendedTimeOut: extendedTimeOut,
      closeButton: closeButton,
      debug: debug,
      progressBar: progressBar,
      preventDuplicates: preventDuplicates,
      newestOnTop: newestOnTop,
      showEasing: showEasing,
      hideEasing: hideEasing,
      showMethod: showMethod,
      hideMethod: hideMethod,
      showDuration: showDuration,
      hideDuration: hideDuration
    };
  
    // setTimeout(() => toastr.success(`Settings updated `), 300)
    //Toaster Types
    if (toastType === "info") toastr.info(message, title);
    else if (toastType === "warning") toastr.warning(message, title);
    else if (toastType === "error") toastr.error(message, title);
    else toastr.success(message, title);
  }
  const { users } = useSelector(state => ({
    users: state.contacts.mega,
  }));

  useEffect(() => {
    if (users && !users.length) {
      dispatch(onGetContact());

    }
  }, [dispatch, users]);

  useEffect(() => {
    setContactEdit(users[0]);
  }, [users]);

  useEffect(() => {
    if (!isEmpty(users)) {
      setContactEdit(users[0]);
    }
  }, [users]);

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumb */}
          <Breadcrumbs title="Yönetim" breadcrumbItem="İletişim Bilgileri" />

          <Row>
            <Col xs="12">
              <Card>
                <CardBody>
                  <CardTitle>İletişim Bilgileri</CardTitle>

                  <Form
                    onSubmit={(e) => {
                      e.preventDefault();
                      validation.handleSubmit();
                      return false;
                    }}
                  >
                    <Row>

                      <div className="mb-3">
                        <Label htmlFor="adress">Adres</Label>
                        <Input
                          id="adress"
                          name="adress"
                          type="text"
                          className="form-control"
                          placeholder="Adres"
                          validate={{
                            required: { value: true },
                          }}
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.adress || ""}
                          invalid={
                            validation.touched.adress && validation.errors.adress ? true : false
                          }
                        />
                        {validation.touched.adress && validation.errors.adress ? (
                          <FormFeedback type="invalid">{validation.errors.adress}</FormFeedback>
                        ) : null}

                      </div>

                      <div className="mb-3">
                        <Label htmlFor="adress">Enlem</Label>
                        <Input
                          id="lat"
                          name="lat"
                          type="text"
                          className="form-control"
                          placeholder="Adres"
                          validate={{
                            required: { value: true },
                          }}
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.lat || ""}
                          invalid={
                            validation.touched.lat && validation.errors.lat ? true : false
                          }
                        />
                        {validation.touched.lat && validation.errors.lat ? (
                          <FormFeedback type="invalid">{validation.errors.lat}</FormFeedback>
                        ) : null}

                      </div>

                      <div className="mb-3">
                        <Label htmlFor="adress">Boylam</Label>
                        <Input
                          id="long"
                          name="long"
                          type="text"
                          className="form-control"
                          placeholder="Adres"
                          validate={{
                            required: { value: true },
                          }}
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.long || ""}
                          invalid={
                            validation.touched.long && validation.errors.long ? true : false
                          }
                        />
                        {validation.touched.long && validation.errors.long ? (
                          <FormFeedback type="invalid">{validation.errors.long}</FormFeedback>
                        ) : null}

                      </div>


                      <div className="mb-3">
                        <Label htmlFor="gsmNumber">Telefon Numarası</Label>
                        <Input
                          id="gsmNumber"
                          name="gsmNumber"
                          type="text"
                          className="form-control"
                          placeholder="Telefon Numarası"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.gsmNumber || ""}
                          invalid={
                            validation.touched.gsmNumber && validation.errors.gsmNumber ? true : false
                          }
                        />
                        {validation.touched.gsmNumber && validation.errors.gsmNumber ? (
                          <FormFeedback type="invalid">{validation.errors.gsmNumber}</FormFeedback>
                        ) : null}

                      </div>


                      <div className="mb-3">
                        <Label htmlFor="email">Eposta</Label>
                        <Input
                          id="email"
                          name="email"
                          type="text"
                          className="form-control"
                          placeholder="Eposta Adresi"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.email || ""}
                          invalid={
                            validation.touched.email && validation.errors.email ? true : false
                          }
                        />
                        {validation.touched.email && validation.errors.email ? (
                          <FormFeedback type="invalid">{validation.errors.email}</FormFeedback>
                        ) : null}

                      </div>

                      <div className="mb-3">
                        <Label htmlFor="facebook">Facebook Adresi</Label>
                        <Input
                          id="facebook"
                          name="facebook"
                          type="text"
                          className="form-control"
                          placeholder="Facebook Adresi"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.facebook || ""}
                          invalid={
                            validation.touched.facebook && validation.errors.facebook ? true : false
                          }
                        />
                        {validation.touched.facebook && validation.errors.facebook ? (
                          <FormFeedback type="invalid">{validation.errors.facebook}</FormFeedback>
                        ) : null}

                      </div>

                      <div className="mb-3">
                        <Label htmlFor="twitter">Twitter Adresi</Label>
                        <Input
                          id="twitter"
                          name="twitter"
                          type="text"
                          className="form-control"
                          placeholder="Twitter Adresi"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.twitter || ""}
                          invalid={
                            validation.touched.twitter && validation.errors.twitter ? true : false
                          }
                        />
                        {validation.touched.twitter && validation.errors.twitter ? (
                          <FormFeedback type="invalid">{validation.errors.twitter}</FormFeedback>
                        ) : null}

                      </div>
                      <div className="mb-3">
                        <Label htmlFor="instagram">Instagram Adresi</Label>
                        <Input
                          id="instagram"
                          name="instagram"
                          type="text"
                          className="form-control"
                          placeholder="Instagram Adresi"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.instagram || ""}
                          invalid={
                            validation.touched.instagram && validation.errors.instagram ? true : false
                          }
                        />
                        {validation.touched.instagram && validation.errors.instagram ? (
                          <FormFeedback type="invalid">{validation.errors.instagram}</FormFeedback>
                        ) : null}

                      </div>


                    </Row>
                    <div className="d-flex flex-wrap gap-2">
                      <Button type="submit" color="primary" className="btn ">
                        Kaydet
                      </Button>
                      <Button type="submit" color="secondary" className=" ">
                        İptal
                      </Button>
                    </div>
                  </Form>
                </CardBody>
              </Card>


            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default EcommerceAddProduct
