import React, { useEffect, useState, useRef, useMemo } from "react";
import { Link } from "react-router-dom";
import withRouter from "components/Common/withRouter";
import TableContainer from "../../../components/Common/TableContainer";
import {
  Card,
  CardBody,
  Col,
  Container,
  Row,
  Modal,
  ModalHeader,
  ModalBody,
  Label,
  FormFeedback,
  UncontrolledTooltip,
  Input,
  Form,
} from "reactstrap";
import * as Yup from "yup";
import { useFormik } from "formik";

import { Name, Status, Date } from "./contactlistCol";

//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb";
import DeleteModal from "components/Common/DeleteModal";

import {
  getDynamicForm as onGetDynamicForm,
  addDynamicForm as onaddDynamicForm,
  updateDynamicForm as onUpdateDynamicForm,
  deleteDynamicForm as onDeleteDynamicForm,
} from "store/contacts/actions";
import { isEmpty } from "lodash";
import moment from "moment";

//redux
import { useSelector, useDispatch } from "react-redux";

const ContactsList = props => {

  //meta title
  document.title = "Dinamik Formlar | MEGA ADMIN PANELI";

  const dispatch = useDispatch();
  const [contact, setContact] = useState();
  const [getEdit, setEdit] = useState();

  // validation
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      name: (getEdit && getEdit.Dynamic_Form_Name) || "",
      startAge: (getEdit && getEdit.Dynamic_Form_StartAge) || "",
      finishAge: (getEdit && getEdit.Dynamic_Form_FinishAge) || '',
    },
    validationSchema: Yup.object({
      name: Yup.string().required("Lütfen Adınızı Giriniz"),
      startAge: Yup.string().required("Başlangıç Yaşınızı Giriniz"),
      finishAge: Yup.string().required("Bitiş Yaşınızı Giriniz")
    }),
    onSubmit: values => {
      var localDate = moment().local();

      if (isEdit) {
        const updateUser = {
          ID: getEdit.ID,
          Dynamic_Form_Name:values.name,
          Dynamic_Form_StartAge: values.startAge,
          Dynamic_Form_FinishAge: values.finishAge,
          Dynamic_Form_IsMeram: getIsMeram,
          Dynamic_Form_IsActive: getActive
                };
                console.log("updateUser",updateUser)
        // update user
        dispatch(onUpdateDynamicForm(updateUser));
        setIsEdit(false);
        validation.resetForm();
      } else {
        const newUser = {
          Dynamic_Form_Name:values["name"],
          Dynamic_Form_StartAge: values["startAge"],
          Dynamic_Form_FinishAge: values["finishAge"],
          Dynamic_Form_IsMeram: getIsMeram,
          Dynamic_Form_IsActive: getActive,
          Dynamic_Form_CreatedDateTime: localDate
        };
        // save new user
        dispatch(onaddDynamicForm(newUser));
        validation.resetForm();
      }
      toggle();
    },
  });

  const { users } = useSelector(state => ({
    users: state.contacts.dynamic,
  }));

  const [userList, setUserList] = useState([]);
  const [modal, setModal] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [getEditForm, setEditForm] = useState(false);
  const [getActive, setActive] = useState(false);
  const [getIsMeram, setIsMeram] = useState(false);

  const columns = useMemo(
    () => [
      {
        Header: "#",
        Cell: () => {
          return <input type="checkbox" className="form-check-input" />;
        },
      },
     
      {
        Header: "Form Adı",
        accessor: "Dynamic_Form_Name",
        filterable: true,
        Cell: cellProps => {
          return <Name {...cellProps} />;
        },
      },
    
      {
        Header: "Meramda Oturuyor Musun?",
        accessor: "Dynamic_Form_IsMeram",
        filterable: true,
        disableFilters: true,
        Cell: cellProps => {
          return <Status {...cellProps} />;
        },
      },
  
      {
        Header: "Durum",
        accessor: "Dynamic_Form_IsActive",
        filterable: true,
        disableFilters: true,
        Cell: cellProps => {
          return <Status {...cellProps} />;
        },
      },
      {
        Header: 'Kayıt Tarihi',
        accessor: 'Dynamic_Form_CreatedDateTime',
        filterable: true,
        disableFilters: true,
        Cell: (cellProps) => {
          return <Date {...cellProps} />;
        }
      },
      {
        Header: "İşlemler",
        Cell: cellProps => {
          return (
            <div className="d-flex gap-3">
                <Link
              
                to="/addDynamicFormElement"
                className="text-danger"
                state={{ _item: cellProps.row.original }}

              >
              
                Element Ekle
              </Link>

              <Link
                to="#"
                className="text-success"
                onClick={() => {
                  const userData = cellProps.row.original;
                  handleUserClick(userData);
                }}
              >
                <i className="mdi mdi-pencil font-size-18" id="edittooltip" />
                <UncontrolledTooltip placement="top" target="edittooltip">
                  Düzenle
                </UncontrolledTooltip>
              </Link>
              <Link
                to="#"
                className="text-danger"
                onClick={() => {
                  const userData = cellProps.row.original;
                  onClickDelete(userData);
                }}
              >
                <i className="mdi mdi-delete font-size-18" id="deletetooltip" />
                <UncontrolledTooltip placement="top" target="deletetooltip">
                  Sil
                </UncontrolledTooltip>
              </Link>
            </div>
          );
        },
      },
    ],
    []
  );

  useEffect(() => {
    if (users && !users.length) {
      dispatch(onGetDynamicForm());
      setIsEdit(false);
   }
  }, [dispatch, users]);

  useEffect(() => {
    setContact(users);
    setIsEdit(false);
  }, [users]);

  useEffect(() => {
    if (!isEmpty(users) && !!isEdit) {
      setContact(users);
      setIsEdit(false);
    }
  }, [users]);

  const toggle = () => {
    setModal(!modal);
  };

  const handleUserClick = arg => {
    const user = arg;
    setEditForm(user)
    setContact(user);
    setIsEdit(true);

    setActive(user.Dynamic_Form_IsActive)
    setIsMeram(user.Dynamic_Form_IsMeram)

    setEdit(user)
    toggle();
  };

  var node = useRef();
  const onPaginationPageChange = page => {
    if (
      node &&
      node.current &&
      node.current.props &&
      node.current.props.pagination &&
      node.current.props.pagination.options
    ) {
      node.current.props.pagination.options.onPageChange(page);
    }
  };

  //delete customer
  const [deleteModal, setDeleteModal] = useState(false);

  const onClickDelete = users => {
    setContact(users);
    setDeleteModal(true);
  };

  const handleDeleteUser = () => {
    if (contact && contact.ID) {
      dispatch(onDeleteDynamicForm(contact.ID));
    }
    onPaginationPageChange(1);
    setDeleteModal(false);
  };

  const handleUserClicks = () => {
    setUserList("");
    setIsEdit(false);
    toggle();
  };

  const keyField = "id";

  return (
    <React.Fragment>
      <DeleteModal
        show={deleteModal}
        onDeleteClick={handleDeleteUser}
        onCloseClick={() => setDeleteModal(false)}
      />
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumbs */}
          <Breadcrumbs title="Yönetim" breadcrumbItem="DİNAMİK FORMLAR" />
          <Row>
            <Col lg="12">
              <Card>
                <CardBody>
                  <TableContainer
                    columns={columns}
                    data={users}
                    isGlobalFilter={true}
                    isAddDynamicForm={true}
                    handleOrderClicks={handleUserClicks}
                    customPageSize={10}
                    className="custom-header-css"
                  />

                  <Modal isOpen={modal} toggle={toggle}>
                    <ModalHeader toggle={toggle} tag="h4">
                      {!!isEdit ? "Dinamik Formu Düzenle" : "Dinamik Form Ekle"}
                    </ModalHeader>
                    <ModalBody>
                      <Form
                        onSubmit={e => {
                          e.preventDefault();
                          validation.handleSubmit();
                          return false;
                        }}
                      >
                        <Row>
                          <Col xs={12}>
                            <div className="mb-3">
                              <Label className="form-label">Form Adı</Label>
                              <Input
                                name="name"
                                type="text"
                                placeholder="Form Adı"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.name || ""}
                                invalid={
                                  validation.touched.name &&
                                    validation.errors.name
                                    ? true
                                    : false
                                }
                              />
                              {validation.touched.name &&
                                validation.errors.name ? (
                                <FormFeedback type="invalid">
                                  {validation.errors.name}
                                </FormFeedback>
                              ) : null}
                            </div>
                            <div className="mb-3">
                              <Label className="form-label">Başlangıç Yaşı</Label>
                              <Input
                                name="startAge"
                                label="startAge"
                                type="text"
                                placeholder="Başlangıç Yaşını Giriniz"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.startAge || ""}
                                invalid={
                                  validation.touched.startAge &&
                                    validation.errors.startAge
                                    ? true
                                    : false
                                }
                              />
                              {validation.touched.startAge &&
                                validation.errors.startAge ? (
                                <FormFeedback type="invalid">
                                  {validation.errors.startAge}
                                </FormFeedback>
                              ) : null}
                            </div>
                            <div className="mb-3">
                              <Label className="form-label">Bitiş Yaşı</Label>
                              <Input
                                name="finishAge"
                                label="finishAge"
                                type="text"
                                placeholder="Mail Adresini Giriniz"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.finishAge || ""}
                                invalid={
                                  validation.touched.finishAge &&
                                    validation.errors.finishAge
                                    ? true
                                    : false
                                }
                              />
                              {validation.touched.finishAge &&
                                validation.errors.finishAge ? (
                                <FormFeedback type="invalid">
                                  {validation.errors.finishAge}
                                </FormFeedback>
                              ) : null}
                            </div>
                           
                      

                            <div className="d-flex">
                    <Label className="form-label">Meramda Otursun</Label>

                          <div className="square-switch">

                            <input
                              type="checkbox"
                              id="square-switch1"
                              className="switch"
                              defaultChecked={getIsMeram}
                              onChange={() =>
                                setIsMeram(!getIsMeram)
                              }
                            />
                            <label
                         htmlFor="square-switch1"
                              data-on-label="Aktif "
                              data-off-label="Pasif"
                            />
                          </div>

                          <Label className="form-label">Aktiflik Durumu</Label>

                          <div className="square-switch">

                            <input
                              type="checkbox"
                              id="square-switch2"
                              className="switch switch-info"
                              defaultChecked={getActive}
                              onChange={() =>
                                setActive(!getActive)
                              }
                            />
                            <label
                              htmlFor="square-switch2"
                              data-on-label="Aktif"
                              data-off-label="Pasif"
                            />
                          </div>
                       
                        </div>
                          </Col>
                        </Row>
                        <Row>
                          <Col>
                            <div className="text-end">
                              <button
                                type="submit"
                                className="btn btn-success save-user"
                              >
                                Kaydet
                              </button>
                            </div>
                          </Col>
                        </Row>
                      </Form>
                    </ModalBody>
                  </Modal>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default withRouter(ContactsList);
