import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import {
  Button,
  Card,
  CardBody,
  CardTitle,
  Col,
  Container,
  Form,
  Input,
  Label,
  Row,
  FormFeedback
} from "reactstrap";
import { useSelector, useDispatch } from "react-redux";
import { isEmpty } from "lodash";
import Breadcrumbs from "../../../components/Common/Breadcrumb";
import "react-rangeslider/lib/index.css"
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { useFormik } from "formik";
import * as Yup from "yup";
import {
  updateAbout as onUpdateAbout,
  getAbout as onGetAbout,
} from "store/contacts/actions";

import toastr from "toastr";
import "toastr/build/toastr.min.css";

import Editor from 'ckeditor5-custom-build/build/ckeditor';
import { CKEditor } from '@ckeditor/ckeditor5-react'

import { Base64 } from 'js-base64';
import Dropzone from 'react-dropzone-uploader';
import axios from "axios";
import ReactPlayer from "react-player";

const EcommerceAddProduct = () => {
  document.title = "Hakkımızda Bilgileri | Yakın Mutfak Admin Paneli";

  const dispatch = useDispatch();

  const [getContactEdit, setContactEdit] = useState(null);
  const [getHtmlDataLong, setHtmlDataLong] = useState(null);

  const [getHtmlVision, setHtmlVision] = useState(null);
  const [getHtmlMision, setHtmlMision] = useState(null);
  const [getHtmlExperience, setHtmlExperience] = useState(null);
  const [getHtmlDataManagement, setHtmlDataManagement] = useState(null);
  const [getFileName, setFileName] = useState(null);

  function uploadAdapter(loader) {
    return {
      upload: () => {
        return new Promise(async (resolve, reject) => {
          try {
            const file = await loader.file; // loader.file bir Promise nesnesi döndürür
  
            // Burada file değişkeni Promise'ın çözüldüğü (fulfilled) durumda, yani File nesnesini içerir
            const body = new FormData();
            body.append('dataFile', file);
            console.log("file1c2313",file);
  
            try {
              const response = await axios.request({
                method: "POST",
                url: `https://berlinadmin.stechomeyazilim.info:9091/uploadfile`,
                data: body, // axios'ta "data" kullanın, "body" değil
                headers: {
                  "Content-Type": "multipart/form-data"
                }
              });
  
              resolve({
                default: `https://megasubdomain.stechomeyazilim.info/${response.data.file.filename}`
              });
            } catch (error) {
              // Hata oluştuğunda throw ile hata fırlatın
              throw error;
            }
  
            // File nesnesini kullanarak istediğiniz işlemleri yapabilirsiniz
          } catch (error) {
            reject(error);
          }
        });
      },
      abort: () => {}
    };
  }
  
  function uploadPlugin(editor) {
    editor.plugins.get("FileRepository").createUploadAdapter = (loader) => {
      return uploadAdapter(loader);
    };
  }

  
  function showToast(type) {
    let ele = null
    let message = "";
  
     if(type == true){
       ele = "success"
       message = "Başarıyla Bilgileriniz Güncellendi"
    } else{
       ele = "error"
       message = getFalseDesc
    };
    const position = ["toast-top-right"];
    let toastType;
    const title = "Başlık";
  
  
    //Close Button
    const closeButton = true;
  
    //Debug
    const debug = false;
  
    //Progressbar
    const progressBar = true;
  
    //Duplicates
    const preventDuplicates = false;
  
    //Newest on Top
    const newestOnTop = true;
  
    //position class
    let positionClass = "toast-top-right";
  
    //Show Easing
    const showEasing = "swing";
  
    //Hide Easing
    const hideEasing = "linear";
  
    //show method
    const showMethod = "fadeIn";
  
    //Hide method
    const hideMethod = "fadeOut";
  
    //show duration
    const showDuration = 300;
  
    //Hide duration
    const hideDuration = 1000;
  
    //timeout
    const timeOut = 5000;
  
    //extended timeout
    const extendedTimeOut = 1000;
  
    //Fetch checked Type
   
     toastType = ele;
    
  
    toastr.options = {
      positionClass: positionClass,
      timeOut: timeOut,
      extendedTimeOut: extendedTimeOut,
      closeButton: closeButton,
      debug: debug,
      progressBar: progressBar,
      preventDuplicates: preventDuplicates,
      newestOnTop: newestOnTop,
      showEasing: showEasing,
      hideEasing: hideEasing,
      showMethod: showMethod,
      hideMethod: hideMethod,
      showDuration: showDuration,
      hideDuration: hideDuration
    };
  
    // setTimeout(() => toastr.success(`Settings updated `), 300)
    //Toaster Types
    if (toastType === "info") toastr.info(message, title);
    else if (toastType === "warning") toastr.warning(message, title);
    else if (toastType === "error") toastr.error(message, title);
    else toastr.success(message, title);
  }



  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      mission: (getContactEdit && getContactEdit.Mission) || '',
      vission: (getContactEdit && getContactEdit.Vision) || '',
      shortdesc: (getContactEdit && getContactEdit.ShortDesc) || '',




    },
    validationSchema: Yup.object({

    }),
    onSubmit: (values) => {

      const updateOrder = {
        ID: users ? users[0].ID : 0,

        AboutDesc: getHtmlDataLong != null ? Base64.encode(getHtmlDataLong) : null,
       // AboutDescEng: getHtmlDataLongEng != null ? Base64.encode(getHtmlDataLongEng) : null,
       // AboutDescThird: getHtmlDataLongThird != null ? Base64.encode(getHtmlDataLongThird) : null,

        Mission: getHtmlMision != null ? Base64.encode(getHtmlMision) : null,
        Vision: getHtmlVision != null ? Base64.encode(getHtmlVision) : null,
        Experience: getHtmlExperience != null ? Base64.encode(getHtmlExperience) : null,
        OURMANAGEMENTPOLICY: getHtmlDataManagement != null ? Base64.encode(getHtmlDataManagement) : null,
        Video: getFileName
      };
      // update order
      dispatch(onUpdateAbout(updateOrder));
      //validation.resetForm();
      setTimeout(() =>  dispatch(onGetAbout()), 1500)

    
      showToast(true)
    },
  });

  const { users } = useSelector(state => ({
    users: state.contacts.about,
  }));

  useEffect(() => {
    if (users && !users.length) {
      dispatch(onGetAbout());

      
    }
  }, [dispatch, users]);

  const handleChangeStatus = ({ xhr }) => {
    if (xhr) {
      xhr.onreadystatechange = () => {
        if (xhr.readyState === 4) {
          const result = JSON.parse(xhr.response);
          console.log("msdlşf",result.file)
          setFileName(result.file.filename)

        }
      }
    }
  }


  useEffect(() => {
    if (users.length > 0) {
      setContactEdit(users[0]);

      if (users[0].AboutDesc != null) {
        setHtmlDataLong(Base64.decode(users[0].AboutDesc))
  
      }

      if (users[0].Mission != null) {
        setHtmlMision(Base64.decode(users[0].Mission))
  
      }

      if (users[0].Vision != null) {
        setHtmlVision(Base64.decode(users[0].Vision))
  
      }

      if (users[0].Experience != null) {
        setHtmlExperience(Base64.decode(users[0].Experience))
  
      }
  
      if (users[0].OURMANAGEMENTPOLICY != null) {
        setHtmlDataManagement(Base64.decode(users[0].OURMANAGEMENTPOLICY))
  
      }

      if(users[0].Video != null){
        setFileName(users[0].Video)

      }
  
      
    }
  }, [users]);


  const getUploadParams = ({ file }) => {
    const body = new FormData();
    body.append('dataFile', file);
    return { url: 'https://megaadmin.stechomeyazilim.info:8080/uploadfile', body }
  }


  const Preview = ({ meta }) => {
    const { name, percent, status, previewUrl } = meta;
    return (
      <div className="preview-box">
        <img src={previewUrl} /> <span className="name">{name}</span> - <span className="status">{status == "done" ? "Başarıyla Yüklendi" : "Yükleniyor"}</span>{status !== "done" && <span className="percent"> ({Math.round(percent)}%)</span>}
      </div>
    )
  }
  useEffect(() => {
    if (!isEmpty(users)) {
      setContactEdit(users[0]);
    }
  }, [users]);

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumb */}
          <Breadcrumbs title="Yönetim" breadcrumbItem="Hakkımızda Bilgileri" />

          <Row>
            <Col xs="12">
              <Card>
                <CardBody>
                  <CardTitle>Hakkımızda Bilgileri</CardTitle>

                  <Form
                    onSubmit={(e) => {
                      e.preventDefault();
                      validation.handleSubmit();
                      return false;
                    }}
                  >
                    <Row>

                    

                      <div className="mb-3">
                        <Label className="form-label">Misyon</Label>

                        <Form method="post">
                        <CKEditor
                                  editor={Editor}
                                  config={{
                                    // @ts-ignore
                                    extraPlugins: [uploadPlugin]
                                  }}
                            data={getHtmlMision}
                            onReady={editor => {
                              // You can store the "editor" and use when it is needed.
                              console.log('Editor is ready to use!', editor);
                            }}
                            onChange={(event, editor) => {
                              const data = editor.getData();
                              setHtmlMision(data)

                            }}
                          />
                        </Form>
                      </div>

                      <div className="mb-3">
                        <Label className="form-label">Vizyon</Label>

                        <Form method="post">
                          <CKEditor
                                  editor={Editor}
                                  data={getHtmlVision}
                                  config={{
                                    // @ts-ignore
                                    extraPlugins: [uploadPlugin]
                                  }}
                            onReady={editor => {
                              // You can store the "editor" and use when it is needed.
                              console.log('Editor is ready to use!', editor);
                            }}
                            onChange={(event, editor) => {
                              const data = editor.getData();
                              setHtmlVision(data)

                            }}
                          />
                        </Form>
                      </div>
                  

                      <div className="mb-3">
                        <Label className="form-label">Deneyim</Label>

                        <Form method="post">
                        <CKEditor
                                  editor={Editor}
                                  config={{
                                    // @ts-ignore
                                    extraPlugins: [uploadPlugin]
                                  }}
                            data={getHtmlExperience}
                            onReady={editor => {
                              // You can store the "editor" and use when it is needed.
                              console.log('Editor is ready to use!', editor);
                            }}
                            onChange={(event, editor) => {
                              const data = editor.getData();
                              setHtmlExperience(data)

                            }}
                          />
                        </Form>
                      </div>
              
                      <div className="mb-3">
                        <Label className="form-label">Yönetim Politikamız</Label>

                        <Form method="post">
                        <CKEditor
                                  editor={Editor}
                                  config={{
                                    // @ts-ignore
                                    extraPlugins: [uploadPlugin]
                                  }}
                            data={getHtmlDataManagement}
                            onReady={editor => {
                              // You can store the "editor" and use when it is needed.
                              console.log('Editor is ready to use!', editor);
                            }}
                            onChange={(event, editor) => {
                              const data = editor.getData();
                              setHtmlDataManagement(data)

                            }}
                          />
                        </Form>
                      </div>


                      <div className="mb-3">
                        <Label className="form-label">Hakkımızda Açıklama</Label>

                        <Form method="post">
                        <CKEditor
                                  editor={Editor}
                                  config={{
                                    // @ts-ignore
                                    extraPlugins: [uploadPlugin]
                                  }}
                            data={getHtmlDataLong}
                            onReady={editor => {
                              // You can store the "editor" and use when it is needed.
                              console.log('Editor is ready to use!', editor);
                            }}
                            onChange={(event, editor) => {
                              const data = editor.getData();
                              setHtmlDataLong(data)

                            }}
                          />
                        </Form>
                      </div>

                      <div className="mb-3">
                              <Col xs={12} className="mb-3">
                                <label htmlFor="resume">Video</label>
                                <Dropzone
                                  getUploadParams={getUploadParams}
                                  onChangeStatus={handleChangeStatus}
                                  styles={{
                                    dropzone: { overflow: 'auto', border: '1px solid #999', background: '#f5f5f5' },
                                    inputLabelWithFiles: { margin: '20px 3%' }
                                  }}

                                  canRemove={false}
                                  PreviewComponent={Preview}
                                  accept="video/*"
                                />

                              </Col>

                              <div>
                              <ReactPlayer
        url={'https://megasubdomain.stechomeyazilim.info/' + getFileName}
        controls
        width="50%"
        height={150}
      />
                </div>

                            </div>

                    </Row>
                    <div className="d-flex flex-wrap gap-2">
                      <Button type="submit" color="primary" className="btn ">
                        Kaydet
                      </Button>
                      <Button type="submit" color="secondary" className=" ">
                        İptal
                      </Button>
                    </div>
                  </Form>
                </CardBody>
              </Card>


            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default EcommerceAddProduct
